/*
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-07-20 17:27:12
 * @LastEditTime: 2022-08-31 15:34:24
 */
import axios from '@/utils/request'
let Api = {
    volunteerList: "/volunteer/information/list",
    recordChange: "/volunteer/information/changeScore",
    volunteerActivity: "/volunteer/information/volunteerActivity",
    changeRecordsDetail: "/deductionDetails/list",
    voteSetList: "/volunteer/information/reviewList",
    voteSetDetail: "/volunteer/information/",
    voteSetVerify: "/volunteer/information/review/",
	starVolunteerList: "/volunteer/information/starList",
	verifyYearList: "/reviewYear/list",
	verifyYearAdd: "/reviewYear/",
	verifyYearOpen: "/volunteer/information/open",
	starVolunteerDetail: "/volunteer/information/star/",
    registNumChange: "/volunteer/information/changeRegNum",
    registNumBind: "/volunteer/information/bindRegNum",
    starVolunteerDownload: "/volunteer/information/starExport",
    volunteerDownload: "/volunteer/information/exportPage",
}
// 志愿者列表
export function listVolunteer(params) {
    return axios({
        method: 'get',
        url: Api.volunteerList,
        params: params
    })
}
// 改分
export function changeRecord(params) {
    return axios({
        method: 'post',
        url: Api.recordChange,
        params: params
    })
}
// 志愿者参加的活动
export function activityVolunteer(params) {
    return axios({
        method: 'get',
        url: Api.volunteerActivity,
        params: params
    })
}
// 志愿者加减分记录
export function detailChangeRecords(params) {
    return axios({
        method: 'get',
        url: Api.changeRecordsDetail,
        params: params
    })
}
// 志愿者评选设置列表
export function listVoteSet(params) {
    return axios({
        method: 'get',
        url: Api.voteSetList,
        params: params
    })
}
// 志愿者评选设置详情
export function detailVoteSet(id, params) {
    return axios({
        method: 'get',
        url: Api.voteSetDetail + id,
        params: params
    })
}
// 志愿者评选设置评定
export function verifyVoteSet(id, params) {
    return axios({
        method: 'put',
        url: Api.voteSetVerify + id,
        data: params
    })
}
// 星级志愿者列表
export function listStarVolunteer(params) {
    return axios({
        method: 'get',
        url: Api.starVolunteerList,
        params: params
    })
}
// 评审年份列表
export function listVerifyYear(params) {
    return axios({
        method: 'get',
        url: Api.verifyYearList,
        params: params
    })
}
// 评审年份新增
export function addVerifyYear(params) {
    return axios({
        method: 'post',
        url: Api.verifyYearAdd,
        params: params
    })
}
// 开启上传通道
export function openVerifyYear(params) {
    return axios({
        method: 'put',
        url: Api.verifyYearOpen,
        params: params
    })
}
// 星级志愿者详情
export function detailStarVolunteer(id, params) {
    return axios({
        method: 'get',
        url: Api.starVolunteerDetail + id,
        params: params
    })
}
// 注册号更改
export function changeRegistNum(params) {
    return axios({
        method: 'post',
        url: Api.registNumChange,
        data: params
    })
}
// 注册号绑定
export function bindRegistNum(params) {
    return axios({
        method: 'post',
        url: Api.registNumBind,
        data: params
    })
}
// 星级志愿者导出
export function downloadStarVolunteer(params) {
	return axios({
		method: 'get',
		url: Api.starVolunteerDownload,
		params: params,
		headers: {
		    'X-Requested-With': 'XMLHttpRequest',
		    'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
		
	})
}
export function downloadVolunteer(params) {
	return axios({
		method: 'get',
		url: Api.volunteerDownload,
		params: params,
		headers: {
		    'X-Requested-With': 'XMLHttpRequest',
		    'Content-Type': 'application/json;charset=UTF-8'
		},
		withCredentials: true,
		observe: 'response',
		responseType: 'arraybuffer',
		
	})
}
