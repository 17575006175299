<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:38
-->
<template>
	<div class="manHourFill">
		<div class="manHourFill-content">
			<div class="manHourFill-search">
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">年份</span>
						<el-select class="common-row-ipt" v-model="searchForm.year" placeholder="请选择">
							<el-option v-for="item in yearList" :key="item.year" :label="item.year" :value="item.year">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item">
						<span class="common-row-label">学号</span>
						<el-input class="common-row-ipt" placeholder="请输入学号" v-model="searchForm.studentId" />
					</div>
					<div class="common-row-item">
						<span class="common-row-label">姓名</span>
						<el-input class="common-row-ipt" placeholder="请输入姓名" v-model="searchForm.name" />
					</div>
				</div>
				<div class="common-row">
					<div class="common-row-item">
						<span class="common-row-label">学院</span>
						<el-select class="common-row-ipt" v-model="searchForm.collegeId" placeholder="请选择">
							<el-option v-for="item in collegeList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="common-row-item common-row-default">
						<el-button @click="downLoadStarVolunteer">导出数据</el-button>
						<el-button @click="actionFn('addYear')">年份新增</el-button>
					</div>
					<div class="common-row-item">
						<el-button style="margin-left: auto;" @click="search" type="primary">查询</el-button>
						<el-button @click="reset">重置</el-button>
					</div>
				</div>
			</div>
			<div class="manHourFill-table">
				<el-table :data="tableData" border>
					<el-table-column width="65px" type="index" label="序号">
					</el-table-column>
					<el-table-column prop="name" label="姓名">
					</el-table-column>
					<el-table-column prop="studentId" label="学号">
					</el-table-column>
					<el-table-column width="300px" prop="collegeName" label="学院">
					</el-table-column>
					<!-- <el-table-column prop="workingHours" label="总工时">
					</el-table-column> -->
					<el-table-column prop="efficient" label="星级工时">
					</el-table-column>
					<el-table-column prop="time" label="星级评定">
						<template slot-scope="scope">
							<div class="table-action">
								<i v-for="i in scope.row.star" :key="i" class="iconfont icon-wujiaoxing"></i>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="status" label="状态">
						<template slot-scope="scope">
							<span>{{scope.row.status == 0?"未评定":scope.row.status == 1?"已通过":"已拒绝" }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="reviewPho" label="审核人">
					</el-table-column>
					<el-table-column width="200px" prop="createTime" label="申请时间">
					</el-table-column>
					<el-table-column prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('detail', scope)">详情</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
		<el-dialog @close="closeDialog" title="提示" :modal="false" class="dialog-bg" :show-close="false"
			:visible.sync="dialogVisible" width="520px">
			<div slot="title" class="dialog-header">
				<span>{{dialogTitle}}</span>
				<img @click="closeDialog" src="@/assets/images/common/close.png">
			</div>
			<el-form :model="yearAdd" :rules="yearAddRules" ref="yearAdd" label-width="130px" class="">
				<el-form-item class="yearAdd-dia-formitem" label="年份：" prop="year">
					<!-- <el-date-picker class="yearAdd-dia-ipt" v-model="yearAdd.date" type="year" placeholder="选择年">
					</el-date-picker> -->
					<el-input readonly="readonly" class="yearAdd-dia-ipt" v-model="yearAdd.year" />
				</el-form-item>
				<el-form-item class="yearAdd-dia-formitem" label="" prop="">
					<div class="dia-formitem-mark">
						注：只可新增当前年份，年份系统自动获取，新增成功后需前往评选 设置菜单点击右上角开启按钮，开启成功后小程序即可提交评定申请
					</div>
				</el-form-item>
				<!-- <el-form-item class="yearAdd-dia-formitem" label="开启上传通道：" prop="open">
					<el-switch v-model="yearAdd.open" active-text='是' active-value='1' inactive-text='否' inactive-value='0' active-color="#1CABB9" inactive-color="#DCDFE6">
					</el-switch>
				</el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button @click="submitDialog" type="primary">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		listStarVolunteer,
		listVerifyYear,
		addVerifyYear,
		downloadStarVolunteer,
	} from "@/api/volunteer";
	import {
		recruitFacultyList,
	} from "@/api/recruit";
	export default {
		data() {
			return {
				tableData: [],
				totalCount: 0,
				pageIndex: 1,
				pageSize: 10,
				searchForm: {},
				dialogTitle: "年份新增",
				dialogVisible: false,
				yearAdd: {},
				yearAddRules: {
					year: [{
						required: true,
						message: "请输入年份",
						trigger: "change"
					}],
					open: [{
						required: true,
						message: "请选择开启上传通道",
						trigger: "change"
					}],
				},
				collegeList: [],
				yearList: [],
			};
		},
		watch: {
			dialogVisible: {
				handler(val) {
					if (!val) {
						this.$nextTick(() => {
							this.$refs["yearAdd"].clearValidate()
						})
					}
				},
				deep: true
			}
		},
		mounted() {
			this.getVerifyYearList()
			this.getRecruitFacultyList()
			this.getStarVolunteerList()
		},
		methods: {
			getVerifyYearList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
				};
				listVerifyYear(params).then((res) => {
					this.yearList = res.data
				})
			},
			getRecruitFacultyList() {
				let params = {
					pageIndex: 1,
					pageSize: 999,
				};
				recruitFacultyList(params).then((res) => {
					this.collegeList = res.data
				})
			},
			getStarVolunteerList(type) {
				let params = {
					pageIndex: this.pageIndex,
					pageSize: this.pageSize
				}
				if (this.searchForm.year) {
					this.$set(params, "year", this.searchForm.year)
				}
				if (this.searchForm.studentId) {
					this.$set(params, "studentId", this.searchForm.studentId)
				}
				if (this.searchForm.name) {
					this.$set(params, "name", this.searchForm.name)
				}
				if (this.searchForm.collegeId) {
					this.$set(params, "collegeId", this.searchForm.collegeId)
				}
				if(type == 'downLoad') {
					downloadStarVolunteer(params).then((res) => {
						console.log("downLoadActivity", res)
						const blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
						// const blob = new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"})
						if ('download' in document.createElement('a')) {
							// 非IE下载
							const elink = document.createElement('a')
							elink.download = "星级志愿者表.xlsx"
							elink.style.display = 'none'
							elink.href = URL.createObjectURL(blob)
							document.body.appendChild(elink)
							elink.click()
							URL.revokeObjectURL(elink.href) // 释放URL 对象
							document.body.removeChild(elink)
						} else {
							// IE10+下载
							navigator.msSaveBlob(blob, "星级志愿者表.xlsx")
						}
					})
				} else {
					listStarVolunteer(params).then((res) => {
						if (res.code == 0) {
							this.tableData = res.data
							this.totalCount = res.page.totalCount * 1
						}
					})
				}
			},
			currentChange(val) {
				this.pageIndex = val;
				this.tableData = [];
				this.getStarVolunteerList();
			},
			search() {
				this.pageIndex = 1;
				this.tableData = [];
				this.getStarVolunteerList();
			},
			reset() {
				this.searchForm = {}
				this.pageIndex = 1;
				this.tableData = [];
				this.getStarVolunteerList();
			},
			downLoadStarVolunteer() {
				this.getStarVolunteerList("downLoad");
			},
			actionFn(type, scope) {
				switch (type) {
					case "detail":
						this.$router.push({
							path: `/starVolunteer/detail?detailId=${scope.row.id}`
						})
						break;
					case "addYear":
						let newDate = new Date()
						this.yearAdd.year = newDate.getFullYear()
						this.dialogVisible = true
						break;
				}
			},
			closeDialog() {
				this.dialogVisible = false
			},
			submitDialog() {
				this.$refs["yearAdd"].validate(valid => {
					if (valid) {
						addVerifyYear().then((res) => {
							if(res.code == 0) {
								this.$message({
									type: 'success',
									message: '年份新增成功!'
								});
								this.dialogVisible = false
							}
						})
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}
	
	/deep/.el-button {
		height: 36px;
	}

	/deep/.common-row-default .el-button--default {
		border: 1px solid #DCDFE6 !important;
		color: #606266 !important;
	}

	.manHourFill {
		.yearAdd-dia-formitem {
			margin: 20px 20px 0 0;

			.yearAdd-dia-radio {
				margin: 12px 0 10px 0;
			}

			.yearAdd-dia-ipt {
				width: 360px;
				border: 1px solid #DCDFE6;
				border-radius: 4px;
			}

			.yearAdd-dia-select {
				border: 1px solid #DCDFE6;
				width: 398px;
			}

			.yearAdd-dia-textarea {
				border: 1px solid #DCDFE6;
				border-radius: 4px;
			}

			.dia-formitem-mark {
				width: 360px;
				font-size: 12px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 18px;
				color: #909399;
			}
		}

		.manHourFill-content {
			padding: 20px 20px;


			.manHourFill-search {
				.common-row {
					margin: 16px 0;

					.common-row-item {
						margin-right: 20px;

						.common-row-ipt {
							flex: 1;
							height: 36px;
							line-height: 36px;
							border: 1px solid #EDF1F7;
							border-left: 0;
						}
					}

					.common-row-default {
						display: flex;
					}
				}
			}

			.manHourFill-table {
				margin-top: 20px;
			}
		}
	}
</style>
